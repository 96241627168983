@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  word-break: keep-all;
}

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #212932; */
  /* color: #fff; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
