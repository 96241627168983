.loaders{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.spinner {
  width: 75px;
  height: 75px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #cce2ec;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}
  
@-webkit-keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
        
@keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
