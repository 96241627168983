.app {
  text-align: center;
  overflow: hidden;
}

.board {
  position: relative;
  height: 100%;
}

.popup {
  position: absolute;
  top: -20px;
  width: 100%;
  height: 100%;
  padding-top: 40%;
  background-color: hsla(212, 20%, 16%, 0.9);
  /* margin: 0 auto; */
}

.popup h1 {
  padding: 10vw;
  transform: scale(1.2);
}
